import React from 'react';
import './header.css';
import AppLogo from '../img/FWIC Logo.png';
import close from '../img/nav_Icon_close.png';
import open from '../img/nav_Icon_open.png';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <div>
      <div className='header-wrapper'>
            <div className='top-header'>
                <img src={AppLogo} alt="FWIC Logo" />
                <img id='open-btn' src={open} alt="open"/>
                <ul>
                  <img src={close} alt="close" />
                   <li><Link to="/">Home</Link></li>
                   <li><Link to="#sec-1">Ministries</Link></li>
                   <li><Link to="#sec-2">Our Vision</Link></li>
                   <li><Link to="#sec-3">Contact</Link></li>
                </ul>
            </div>
      </div>
    </div>
  )
}
