import './home.css';
import fortworth from '../img/fortworth.jpeg';
import mail from '../img/Mail.jpg';
import phone from '../img/Phone.jpg';
import locationImg from '../img/Location.jpg';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function Home() {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);
  
  return (
    <main>
      <div className="home-wrapper">
        <div className="home-hero">
          <div className='hero-text'>
            <h1 data-aos="fade-up" data-aos-duration="900">Fort Worth Indian Church</h1>
            <h3 data-aos="fade-up" data-aos-duration="1100">We are glad you are here</h3>
          </div>
        </div>
      </div>

      <section>
        <div className='location-wrapper'>
          <div data-aos="fade-up" data-aos-duration="1000" className='main-location'>
            <h2>Our Location</h2>
            <div className='loc-box'>
              <div className='img-cont'>
                <img data-aos="fade-up" data-aos-duration="1000" src={fortworth} alt="" />
              </div>
              <div data-aos="fade-up" data-aos-duration="1000">
                <div>
                  <h3> Place: 5801 North Fwy, Fort Worth,TX</h3>
                  <p>We gather and worship our living god in this beautiful place and we are
                    having one service every week. Our focuses on word and worship for the community.
                  </p>
                </div>
                <div>
                  <h3>Time:</h3>
                  <p>Every Sunday Evening 6:00 PM to 08:00 PM.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sof-wrapper">
        <div data-aos="fade-up" data-aos-duration="1000" className="sof-hero">
          <div id='sec-1' className='sof-text'>
            <h2>Our Ministries</h2>
            <p>We committed to various ministries within the umbrella of the church, focusing on its ministries within the member
              of our FWIC families with wholistic growth in every single part of the life. And we are open to anybody to be a part of our
              family as a church. FWIC focussing all vertical of the ministry such as Church Ministry, Children Ministry, Youth Ministry,
              Ministries among Men and Women.
            </p>
          </div>
        </div>
        <div className="sof-wrap-card">
          <div className='hover-wrap'>
            <div data-aos="fade-up" data-aos-duration="900" className='hover-open'>
              <h3 className='hover-open-title'>Church Ministry <span></span></h3>
              <p>FWIC Focusses on Word, Worship and Witnessing Jesus Christ through gatherings, fellowship
                with one and other for the glory of the Kingdom God whose name will be glorified through
                out Prayers, Songs, Preaching and more discipline that are pleasing God.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className='hover-open'>
              <h3 className='hover-open-title'>Children Ministry <span></span></h3>
              <p>FWIC Focusses on Word, Worship and Witnessing Jesus Christ through gatherings, fellowship
                with one and other for the glory of the Kingdom God whose name will be glorified through
                out Prayers, Songs, Preaching and more discipline that are pleasing God.
              </p>
            </div>
          </div>

          <div className='hover-wrap'>
            <div data-aos="fade-up" data-aos-duration="900" className='hover-open'>
              <h3 className='hover-open-title'>Youth Ministry <span></span></h3>
              <p>FWIC Focusses on Word, Worship and Witnessing Jesus Christ through gatherings, fellowship
                with one and other for the glory of the Kingdom God whose name will be glorified through
                out Prayers, Songs, Preaching and more discipline that are pleasing God.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" className='hover-open'>
              <h3 className='hover-open-title'>Ministries Among Men & Women <span></span></h3>
              <p>FWIC Focusses on Word, Worship and Witnessing Jesus Christ through gatherings, fellowship
                with one and other for the glory of the Kingdom God whose name will be glorified through
                out Prayers, Songs, Preaching and more discipline that are pleasing God.
              </p>
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1000" className='other-ministry'>We are connected internationally and doing mission in India where our ministries reach different heights.
            our ministries in india impacting many lives for christ, it focuses Church Planting, Medical Ministries, Humanitarian Works,
            Seminary and more. <br />
            <Link target='_blank' to="https://ibmasia.org/ministry.html"> <button data-aos="fade-up" data-aos-duration="1000">Know More</button> </Link>
          </p>

        </div>
      </div>

      <section>
        <div className='vision-wrap'>
          <div data-aos="fade-up" data-aos-duration="1000" className='vision-container'>
            <h2>Our Vision</h2>
            <p>
              Our Vision is to locally nurturing the community, and focused on holistic growth with fellowship and gatherings.
              it is also to help indian community to be focused in Christ and keep them alive in faith. Creating local and
              faithful community for the global impact.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className='contact-wrap'>
          <div className='contact-container'>
          <h2>Contact</h2>
            <div className='contact-cards'>
           
              <div className='cards-cont'>
                <div className='img-cn'>
                  <img src={mail} alt="" />
                </div>
                <div>
                  <p>Mail</p>
                  <h3>fortworthindianchurch@gmail.com</h3>
                </div>
              </div>
{/* 
              <div className='cards-cont'>
                <div className='img-cn'>
                  <img src={phone} alt="" />
                </div>
                <div>
                  <p>Message</p>
                  <h3>682-333-9028</h3>
                </div>
              </div> */}

              <div className='cards-cont'>
                <div className='img-cn'>
                  <img src={locationImg} alt="" />
                </div>
                <div>
                  <p>Location</p>
                  <h3>5801 North Fwy, Fort Worth,TX</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='copyrights'>All rights reserved &copy; </div>
      </section>

      <section className='alert'>
        <p>This website is under construction! Will be back soon.</p>
      </section>
      <div className='bg-black'></div>
    </main>
  )
}
